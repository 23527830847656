import * as facility from "./facility";
import * as shift from "./shift";

/**
 * @deprecated
 * This constant blocks code inspection tools, making it hard to find
 * dead code.
 * It also blocks code-splitting.
 * Delete this object and import directly.
 */
export const api = {
  facility,
  shift,
};
